export default {
  3: {
    gradientStart: "#755790",
    gradientEnd: "#523691",
    title: "The Clothesline",
    abbreviation: "TCL",
    site_address: "the-clothesline-laundry.com",
  },
  4: {
    gradientStart: "#0078A2",
    gradientEnd: "#004286",
    title: "Cleveland Laundry Service",
    abbreviation: "CLS",
    site_address: "clevelandlaundryservice.com",
  },
  5: {
    gradientStart: "#303ed1",
    gradientEnd: "#d103b6",
    title: "Snap Laundry",
    abbreviation: "SL",
    site_address: "dormmom.com",
  },
  9: {
    gradientStart: "#059FED",
    gradientEnd: "#0078A2",
    title: "Dorm Mom",
    abbreviation: "DM",
    site_address: "dormmom.com",
  },
  11: {
    gradientStart: "#0080FF",
    gradientEnd: "#3ED3EC",
    title: "Test Site",
    abbreviation: "TS",
    site_address: "taplaundry.com",
  },
  12: {
    gradientStart: "#5fb3fb",
    gradientEnd: "#5fc4b0",
    title: "Soapbox Laundry",
    abbreviation: "SB",
    site_address: "soapboxlaundry.com",
  },
  40: {
    gradientStart: "#3c49ff",
    gradientEnd: "#5674e8",
    title: "Lotus Laundry",
    abbreviation: "LL",
    site_address: "lotuslaundromat.com",
  },
  48: {
    gradientStart: "#155b9b",
    gradientEnd: "#032659",
    title: "Stadium Coin",
    abbreviation: "SCL",
    site_address: "stadiumcoinlaundry.com",
  },
  49: {
    gradientStart: "#155b9b",
    gradientEnd: "#032659",
    title: "At Your Service",
    abbreviation: "AYS",
    site_address: "atyourservicecoinlaundry.com",
  },
  58: {
    gradientStart: "#40b356",
    gradientEnd: "#31944a",
    title: "Mountain Fresh Laundry",
    abbreviation: "MFL",
    site_address: "mountainfreshlaundry.com",
  },
  59: {
    gradientStart: "#5fb3fb",
    gradientEnd: "#5fc4b0",
    title: "Fitted Laundry",
    abbreviation: "FL",
    site_address: "fittedlaundry.com",
  },
  64: {
    gradientStart: "#0000c2",
    gradientEnd: "#00afef",
    title: "Dirty Hamper",
    abbreviation: "DH",
    site_address: "itshampertime.com",
  },
  68: {
    gradientStart: "#56a667",
    gradientEnd: "#39924c",
    title: "Freedom From Laundry",
    abbreviation: "FFL",
    site_address: "freedomfromlaundry.com",
  },
  69: {
    gradientStart: "#c9252c",
    gradientEnd: "#88191E",
    title: "Laundry Station",
    abbreviation: "LS",
    site_address: "laundry-station.com",
  },
  71: {
    gradientStart: "#70b9f9",
    gradientEnd: "#4787f1",
    title: "Swept Away",
    abbreviation: "SA",
    site_address: "sweptawaycleans.com",
  },
  72: {
    gradientStart: "#137A2D",
    gradientEnd: "#45AD1D",
    title: "24-7 Fixit",
    abbreviation: "FI",
    site_address: "24-7fixit.com",
  },
  73: {
    gradientStart: "#0CB787",
    gradientEnd: "#0CB787",
    title: "Element Detail",
    abbreviation: "ED",
    site_address: "elementdetail.com",
  },
  74: {
    gradientStart: "#3cc02b",
    gradientEnd: "#3cc02b",
    title: "WhiteGlove4Less",
    abbreviation: "WG",
    site_address: "whiteglove4less.com",
  },
  75: {
    gradientStart: "#3cc02b",
    gradientEnd: "#3cc02b`",
    title: "Erik's Lemonade Stand",
    abbreviation: "EL",
    site_address: "eriklemonade.com",
  },
  76: {
    gradientStart: "#DD0100",
    gradientEnd: "#DD0100",
    title: "24-7 Heat and Cool",
    abbreviation: "24-7",
    site_address: "24-7heatandcool.com",
  },
  76: {
    gradientStart: "#DD0100",
    gradientEnd: "#DD0100",
    title: "Jobsite Buddy, LLC",
    abbreviation: "JSB",
    site_address: "jobsitebuddy.com",
  },
  76: {
    gradientStart: "#DD0100",
    gradientEnd: "#DD0100",
    title: "Spotless Vision",
    abbreviation: "SV",
    site_address: "jobsitebuddy.com",
  },
};
