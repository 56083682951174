import { Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/outline";
import React, { useEffect, useState } from "react";
import {
  getAllAuthToken,
  getActiveAuthToken,
  setActiveToken,
} from "../../../../lib/cookie";
import TENANTS_STATIC_DATA from "../../../../data/tenants";
import { resetLanguage } from "../../../../helpers/languageHelper";

const TenantSelector = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [tenants, setTenants] = useState([]);
  const [activeTenant, setActiveTenant] = useState(null);

  useEffect(() => {
    setTenants(getAllAuthToken());
    setActiveTenant(getActiveAuthToken());
  }, []);

  return (
    <div className="ml-12 sm:ml-[150px] lg:ml-[250px]">
      <div className="relative h-full">
        <div
          className={`flex justify-between border-l border-r dark:border-primary-700 px-5 items-center ${
            tenants.length > 1 &&
            "hover:bg-gray-100 dark:hover:bg-primary-600 hover:pointer-cursor"
          } h-full`}
          onClick={() => tenants.length > 1 && setIsOpen(!isOpen)}
        >
          {activeTenant && (
            <div className="flex flex-col justify-center items-start pr-5">
              <div className="font-bold text-gray-700 dark:text-gray-200 text-left text-xs md:text-md">
                {activeTenant.site_name
                  ? activeTenant.site_name
                  : TENANTS_STATIC_DATA[activeTenant.site_id]?.title ||
                    "Unknown Company Name"}
              </div>
              <div className="text-xs text-gray-500 dark:text-gray-300 ">
                Admin
              </div>
            </div>
          )}

          {tenants.length > 1 && (
            <ChevronDownIcon
              className="h-5 w-5 text-gray-400 group-hover:text-gray-700"
              aria-hidden="true"
            />
          )}
        </div>

        <Transition
          show={isOpen}
          enter="transition ease-out duration-100"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-95"
        >
          <div
            className="z-50 origin-top-left absolute left-2 mt-2 w-60 rounded-md shadow-lg overflow-y-scroll bg-white dark:bg-gray-800 ring-1 ring-black ring-opacity-5 max-h-[300px]"
            role="menu"
            aria-orientation="vertical"
            aria-labelledby="user-menu"
          >
            {tenants.map((tenant) => (
              <button
                className="block px-4 py-2 text-sm text-gray-700 dark:text-gray-200 hover:bg-gray-100 dark:hover:bg-gray-700 w-full text-left"
                role="menuitem"
                key={tenant.site_id}
                onClick={async () => {
                  setActiveToken(tenant.token);
                  await resetLanguage();
                  setIsOpen(false);
                  location.reload();
                }}
              >
                {tenant.site_name
                  ? tenant.site_name
                  : TENANTS_STATIC_DATA[tenant.site_id]?.title ||
                    "Unknown Company Name"}
              </button>
            ))}
          </div>
        </Transition>
      </div>
    </div>
  );
};

export default TenantSelector;
