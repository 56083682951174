/* This example requires Tailwind CSS v2.0+ */
import React, { Fragment, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XIcon } from "@heroicons/react/solid";

export default function ModalContainer({ children, closeModal, show }) {
  const cancelButtonRef = useRef(null);

  return (
    <Transition.Root show={show} as={Fragment}>
      <Dialog
        as="div"
        className="fixed z-30 inset-0 overflow-y-auto"
        initialFocus={cancelButtonRef}
        onClose={closeModal}
      >
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-800 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="relative inline-block align-bottom bg-white dark:bg-gray-900 rounded-lg text-left shadow-xl print:shadow-none transition-all md:my-8 md:align-middle md:max-w-2xl w-full print:m-0">
              {React.cloneElement(children, {
                closeModal: closeModal,
              })}
              <XIcon
                onClick={() => closeModal()}
                ref={cancelButtonRef}
                className="print:hidden focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 absolute top-0 right-0 m-6 transition-opacity text-gray-400 hover:text-gray-500 cursor-pointer h-6 w-6"
              />
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

// import { Transition } from "@tailwindui/react";

// const ModalContainer = ({ children, closeModal, show }) => {
//   return (
//     <Transition appear show={show}>
// <div class="fixed z-10 inset-0 overflow-y-auto" aria-labelledby="modal-title" role="dialog" aria-modal="true">
//   <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
//           <Transition.Child
//             appear
//             enter="ease-out duration-300"
//             enterFrom="opacity-0"
//             enterTo="opacity-100"
//             leave="ease-in duration-200"
//             leaveFrom="opacity-100"
//             leaveTo="opacity-0"
//           >
//             <div
//               className="fixed inset-0 transition-opacity"
//               aria-hidden="true"
//             >
//               <div
//                 className="absolute inset-0 bg-gray-500 dark:bg-gray-700 opacity-75"
//                 onClick={() => closeModal()}
//               />
//             </div>
//           </Transition.Child>

//           <Transition.Child
//             appear
//             enter="ease-out duration-300"
//             enterFrom="opacity-0 translate-y-12  sm:scale-95"
//             enterTo="opacity-100 translate-y-0 sm:scale-100"
//             leave="ease-in duration-200"
//             leaveFrom="opacity-100 translate-y-0 sm:scale-100"
//             leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
//           >
//             <div
//               className="relative inline-block align-bottom bg-white dark:bg-gray-900 rounded-lg text-left shadow-xl transition-all sm:my-8 sm:align-middle sm:max-w-2xl sm:w-full"
//               role="dialog"
//               aria-modal="true"
//               aria-labelledby="modal-headline"
//             >
//               {React.cloneElement(children, {
//                 closeModal: closeModal,
//               })}

//               <button
//                 onClick={() => closeModal()}
//                 className="absolute top-0 right-0 p-6 transition-opacity text-gray-400 hover:text-gray-500 cursor-pointer"
//               >
//                 <svg
//                   xmlns="http://www.w3.org/2000/svg"
//                   className="h-6 w-6"
//                   fill="none"
//                   viewBox="0 0 24 24"
//                   stroke="currentColor"
//                 >
//                   <path
//                     strokeLinecap="round"
//                     strokeLinejoin="round"
//                     strokeWidth={2}
//                     d="M6 18L18 6M6 6l12 12"
//                   />
//                 </svg>
//               </button>
//             </div>
//           </Transition.Child>
//         </div>
//       </div>
//     </Transition>
//   );
// };

// export default ModalContainer;
